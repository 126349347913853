import { Component } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MENU } from '../../constants/menu.constant';
import { MenuModel } from '../../models/menu.model';

@UntilDestroy()
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  currentRoute: string;
  menu: MenuModel[] = MENU;

  constructor(
    private router: Router
  ) {
    this.currentRoute = this.router.url;

    this.router.events.pipe(untilDestroyed(this)).subscribe((event: Event) => {
      if (event instanceof NavigationEnd)
        this.currentRoute = event.url;
    });
  }

}
