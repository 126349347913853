import { HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private notification: NzNotificationService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((httpError: HttpErrorResponse) => {
                if (httpError.status == 404) return null;
                if (httpError.status == 403) this.notification.error('Acesso negado!', "Você não tem permissão para acessar este recurso!");

                if (httpError.error && httpError.error.errors && httpError.error.errors.length > 0) {
                    httpError.error.errors.forEach(element => {
                        this.notification.error('Ocorreu um erro!', element, { nzDuration: 0 });
                    });
                }

                return throwError(() => httpError);
            })
        ) as Observable<HttpEvent<any>>;
    }
}