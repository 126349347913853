import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_DATE_LOCALE, NZ_I18N } from 'ng-zorro-antd/i18n';
import { pt_BR } from 'ng-zorro-antd/i18n';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';
import { CoreModule } from './core/core.module';
import { AdminComponent } from './layout/admin/admin.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './keycloak-initializer';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { PermissionDeniedModule } from './widget/components/permission-denied/permission-denied.module';
import { ptBR } from 'date-fns/locale';
import { SignalrService } from './shared/services/signalr.service';

registerLocaleData(pt);

const NG_ZORRO = [
  NzLayoutModule,
  NzMenuModule,
  NzIconModule,
  NzResultModule,
  NzButtonModule
]

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    KeycloakAngularModule,
    IconsProviderModule,
    PermissionDeniedModule,
    NgProgressModule,
    NgProgressHttpModule,
    NgxMaskModule.forRoot(),
    NG_ZORRO
  ],
  providers: [
    {
      provide: NZ_I18N,
      useValue: pt_BR
    },
    {
      provide: NZ_DATE_LOCALE,
      useValue: ptBR
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      deps: [KeycloakService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (signalrService: SignalrService) => () => signalrService.initiateSignalrConnection(),
      deps: [SignalrService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
