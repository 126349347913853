import { NzNotificationService } from 'ng-zorro-antd/notification';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';

import { QueueModel } from '../models/queue.model';
import { QueueService } from './queue.service';
import { IHttpConnectionOptions } from '@microsoft/signalr';

@Injectable({
    providedIn: 'root'
})
export class SignalrService {
    connection: signalR.HubConnection;
    urlApi = environment.tapApiSignalR

    constructor(
        private notification: NzNotificationService,
        private queueService: QueueService
    ) { }

    public initiateSignalrConnection() {
        var options: IHttpConnectionOptions = {
            withCredentials: false
        };

        this.connection = new signalR.HubConnectionBuilder().withUrl(this.urlApi, options).withAutomaticReconnect().build();

        this.connection.start().then(() => {
            console.log(`SignalR connection success! connectionId: ${this.connection.connectionId} `);
        }).catch((error) => {
            console.log(`SignalR connection error: ${error}`);
        });

        this.connection.on("BroadcastMessage", (message) => this.broadcastMessage(message));
    }

    private broadcastMessage(message: QueueModel): any {
        if (!message) return;
        this.queueService.update(message);
    }
}