import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { AdminComponent } from './layout/admin/admin.component';
import { PermissionDeniedComponent } from './widget/components/permission-denied/permission-denied.component';
import { UserRoles } from './shared/enums/user-roles.enum';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./domain/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'estudos',
        canActivate: [AuthGuard],
        data: {
          roles: [UserRoles.Administrator]
        },
        loadChildren: () => import('./domain/study/study.module').then(m => m.StudyModule)
      },
      {
        path: 'operadoras',
        loadChildren: () => import('./domain/provider/provider.module').then(m => m.ProviderModule)
      },
      {
        path: 'tabuas',
        loadChildren: () => import('./domain/board/board.module').then(m => m.BoardModule)
      },
      {
        path: 'permission-denied',
        component: PermissionDeniedComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
