import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzResultModule } from "ng-zorro-antd/result";
import { PermissionDeniedComponent } from "./permission-denied.component";

@NgModule({
    declarations: [
        PermissionDeniedComponent
    ],
    imports: [
        RouterModule,
        NzResultModule,
        NzButtonModule
    ],
    exports: [
        PermissionDeniedComponent
    ]
})
export class PermissionDeniedModule {
    
}