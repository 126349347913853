import { Injectable, Injector } from '@angular/core';
import { BaseApi } from 'src/app/shared/apis/base.api';
import { environment } from 'src/environments/environment';
import { QueueModel } from '../models/queue.model';

@Injectable({
    providedIn: 'root'
})
export class QueueApi extends BaseApi<QueueModel> {

    constructor(
        protected override injector: Injector
    ) {
        super(`${environment.tapApi}/filas`, injector);
    }
}