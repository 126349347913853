<div class="sidebar">
    <ul class="sidebar-menu" nz-menu nzTheme="light" nzMode="inline">
        <ng-container *ngFor="let menuItem of menu">
            <ng-container *ngIf="menuItem.subItens">
                <li nz-submenu [nzTitle]="menuItem.text" [nzIcon]="menuItem.icon"
                    [nzOpen]="currentRoute.includes(menuItem.link)" routerLinkActive="active">
                    <ul *ngFor="let subItem of menuItem.subItens">
                        <li nz-menu-item [nzMatchRouter]="true" [routerLink]="subItem.link"
                            [queryParams]="subItem.queryParams">
                            <i *ngIf="subItem.icon" nz-icon [nzType]="subItem.icon"></i>
                            <span>{{subItem.text}}</span>
                        </li>
                    </ul>
                </li>
            </ng-container>

            <ng-container *ngIf="!menuItem.subItens">
                <li nz-menu-item [nzMatchRouter]="true" routerLinkActive="active" [routerLink]="menuItem.link"
                    [queryParams]="menuItem.queryParams">
                    <i nz-icon [nzType]="menuItem.icon"></i>
                    <span>{{menuItem.text}}</span>
                </li>
            </ng-container>
        </ng-container>
    </ul>
</div>