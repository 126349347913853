<ng-progress color="var(--color-primary)" [meteor]="false" [spinner]="false"></ng-progress>
<app-queue></app-queue>

<nz-layout>
    <div *ngIf="!isCollapsed" class="background-sidebar" (click)="isCollapsed = !isCollapsed"></div>
    <app-header (triggerMenu)="isCollapsed = $event" [isCollapsed]="isCollapsed"></app-header>
    <nz-layout>
        <nz-sider nzTheme="light" [nzWidth]="250" nzBreakpoint="lg" [(nzCollapsed)]="isCollapsed" [nzTrigger]="null"
            [nzCollapsedWidth]="0">
            <app-sidebar></app-sidebar>
        </nz-sider>
        <nz-content>
            <router-outlet></router-outlet>
        </nz-content>
    </nz-layout>
</nz-layout>