export const environment = {
  production: true,
  tapApi: "https://api-tap.consultoriaplurall.com.br/api",
  tapApiSignalR: "https://api-tap.consultoriaplurall.com.br/msg/notify",
  keycloak: {
    url: 'https://auth.consultoriaplurall.com.br/auth',
    realm: 'plurall',
    clientId: 'tap-frontend',
  }
};
