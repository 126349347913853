<ng-container *ngIf="(queueService.queues$ | async) as queues">
    <nz-collapse class="fixed-bottom" nzExpandIconPosition="right" *ngIf="queues.length">
        <nz-collapse-panel [nzHeader]="collapseTitle" nzActive>
            <ng-template #collapseTitle>

                <ng-container [ngSwitch]="queuesStatus">
                    <nz-badge *ngSwitchCase="0" nzStatus="default"></nz-badge>
                    <nz-badge *ngSwitchCase="1" nzStatus="processing"></nz-badge>
                    <nz-badge *ngSwitchCase="2" nzStatus="success"></nz-badge>
                    <nz-badge *ngSwitchCase="3" nzStatus="error"></nz-badge>
                    <nz-badge *ngSwitchCase="4" nzStatus="processing"></nz-badge>
                </ng-container>

                Cálculo de estudos
            </ng-template>

            <nz-list nzBordered nzSize="small">
                <nz-list-item *ngFor="let queue of queues">
                    <div nz-row nzAlign="middle" nzGutter="12">
                        <ng-container [ngSwitch]="queue.status">
                            <nz-badge *ngSwitchCase="0" nzStatus="default"></nz-badge>
                            <nz-badge *ngSwitchCase="1" nzStatus="processing"></nz-badge>
                            <nz-badge *ngSwitchCase="2" nzStatus="success"></nz-badge>
                            <nz-badge *ngSwitchCase="3" nzStatus="error"></nz-badge>
                            <nz-badge *ngSwitchCase="4" nzStatus="processing"></nz-badge>
                        </ng-container>

                        <h4>{{queue.titulo}}</h4>
                    </div>

                    <ng-container [ngSwitch]="queue.status">
                        <div *ngSwitchCase="0">
                            <nz-tag nz-tooltip nzTooltipTitle="Este estudo está aguardando para ser calculado">
                                <span nz-icon nzType="clock-circle"></span>
                                <span>aguardando</span>
                            </nz-tag>
                        </div>

                        <div *ngSwitchCase="1">
                            <nz-tag nzColor="blue" nz-tooltip nzTooltipTitle="Este estudo está sendo calculado">
                                <span nz-icon nzType="sync" nzSpin></span>
                                <span>calculando</span>
                            </nz-tag>
                        </div>

                        <div *ngSwitchCase="2" class="pointer">
                            <nz-tag nzColor="success" nz-tooltip nzTooltipTitle="Clique para ver o estudo"
                                (click)="openStudy(queue)">
                                <span nz-icon nzType="check-circle"></span>
                                <span>calculado</span>
                            </nz-tag>
                        </div>

                        <div *ngSwitchCase="3" class="pointer">
                            <nz-tag nzColor="error" nz-tooltip nzTooltipTitle="Clique para ver o erro"
                                (click)="showModalError(queue.errorMessage, queue)">
                                <span nz-icon nzType="close-circle"></span>
                                <span>ocorreu um erro</span>
                            </nz-tag>
                        </div>

                        <div *ngSwitchCase="4" class="pointer">
                            <nz-tag nzColor="blue" nz-tooltip nzTooltipTitle="Gerando arquivos">
                                <span nz-icon nzType="sync" nzSpin></span>
                                <span>gerando arquivos</span>
                            </nz-tag>
                        </div>
                    </ng-container>
                </nz-list-item>
            </nz-list>
        </nz-collapse-panel>
    </nz-collapse>
</ng-container>