import { KeycloakService, KeycloakOptions } from "keycloak-angular";
import { environment } from "src/environments/environment";

export function initializeKeycloak(keycloak: KeycloakService): () => Promise<void> {
    return async () => {
        try {
            const options: KeycloakOptions = {
                config: {
                    url: environment.keycloak.url,
                    realm: environment.keycloak.realm,
                    clientId: environment.keycloak.clientId,
                },
                initOptions: {
                    onLoad: 'login-required'
                }
            };
            await keycloak.init(options);
        } catch (error) {
            console.error(error);
        }
    };
}