import { MenuModel } from "../models/menu.model";

export const MENU: MenuModel[] = [
  {
    text: 'Home',
    icon: 'home',
    link: '/home'
  },
  {
    text: 'Operadoras',
    icon: 'bank',
    link: '/operadoras'
  },
  {
    text: 'Tábuas',
    icon: 'percentage',
    link: '/tabuas'
  },
  {
    text: 'Estudos',
    link: '/estudos',
    icon: 'book',
    subItens: [
      {
        text: 'Despesas individuais',
        link: '/estudos',
        queryParams: {
          despesas: 'individuais'
        },
        icon: 'fall',
      },
      {
        text: 'Despesas coletivas',
        link: '/estudos',
        queryParams: {
          despesas: 'coletivas'
        },
        icon: 'fall',
      },
      {
        text: 'Receitas individuais',
        link: '/estudos',
        queryParams: {
          receitas: 'individuais'
        },
        icon: 'rise',
      },
      {
        text: 'Receitas coletivas',
        link: '/estudos',
        queryParams: {
          receitas: 'coletivas'
        },
        icon: 'rise',
      }
    ]
  }
]