import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { map, Observable, throwError } from "rxjs";
import { BaseModel } from "../models/base.model";

@Injectable({
    providedIn: 'root',
})
export class BaseApi<T extends BaseModel> {
    protected httpClient: HttpClient;

    constructor(
        protected url: String,
        protected injector: Injector
    ) {
        this.httpClient = injector.get(HttpClient);
    }

    getById(id: string): Observable<any> {
        return this.httpClient.get<any>(`${this.url}/${id}`).pipe(
            map(this.getData)
        );
    }

    getAll(params?: { [param: string]: any }): Observable<any> {
        return this.httpClient.get<T[]>(`${this.url}`, { params }).pipe(
            map(this.getData)
        );
    }

    create(resource: T): Observable<T> {
        return this.httpClient.post<T>(this.url.toString(), resource).pipe(
            map(this.getData)
        );
    }

    update(id: string, resource: T): Observable<T> {
        return this.httpClient.put<T>(`${this.url}`, resource).pipe(
            map(this.getData)
        );
    }

    delete(id: string): Observable<null> {
        const END_POINT = `${this.url}/${id}`;
        return this.httpClient.delete<null>(END_POINT).pipe(
            map(this.getData)
        );
    }

    getData(jsonData: any) {
        if (jsonData && jsonData.success)
            return jsonData.data;
        else
            this.handleError(jsonData);
    }

    private handleError(error: any): Observable<any> {
        return throwError(error);
    }
}