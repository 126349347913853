import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

import { QueueApi } from '../apis/queue.api';
import { QueueModel } from '../models/queue.model';

@Injectable({
    providedIn: 'root'
})
export class QueueService {
    queues$: BehaviorSubject<QueueModel[]> = new BehaviorSubject<QueueModel[]>([]);
    get queues(): QueueModel[] {
        return this.queues$.value;
    }

    constructor(
        private queueApi: QueueApi
    ) { }

    loadQueues() {
        this.queueApi.getAll().subscribe(
            (result: QueueModel[]) => {
                if (!result) return;

                this.queues$.next(result);
            }
        );
    }

    async add(queue: QueueModel) {
        this.queues$.next([...this.queues, queue]);
    }

    update(queue: QueueModel) {
        let updatedQueues = this.queues.map(x => {
            if (x.id == queue.id) return queue;
            return x;
        });
        updatedQueues = updatedQueues.filter(x => !x.lido);
        this.queues$.next(updatedQueues);
    }

    remove(queue: QueueModel) {
        const filteredQueues = this.queues.filter(x => x.id != queue.id);
        this.queues$.next(filteredQueues);
    }

    markAsRead(queue: QueueModel) {
        queue.lido = true;
        this.queueApi.update(queue.correlationId, queue).subscribe((result) => this.update(result));
    }
}