<nz-result nzStatus="403"
           nzTitle="403"
           nzSubTitle="Você não tem autorização para acessar essa página.">
    <div nz-result-extra>
        <button nz-button
                nzType="primary"
                routerLink="/">
            Back Home
        </button>
    </div>
</nz-result>