import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() isCollapsed;
  @Output() triggerMenu = new EventEmitter();

  isLoggedIn: boolean;
  userProfile: KeycloakProfile;

  constructor(
    private keycloakService: KeycloakService
  ) { }

  async ngOnInit() {
    this.isLoggedIn = await this.keycloakService.isLoggedIn();

    if(this.isLoggedIn) this.userProfile = await this.keycloakService.loadUserProfile();
  }

  triggerMenuClick() {
    this.isCollapsed = !this.isCollapsed;
    this.triggerMenu.emit(this.isCollapsed);
  }

  login(){
    this.keycloakService.login();
  }

  logout(){
    this.keycloakService.logout();
  }

}
