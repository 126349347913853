import { NzModalService } from 'ng-zorro-antd/modal';
import { StatusRabbitMQMessage } from 'src/app/shared/enums/status-rabbitmq-message.enum';
import { QueueModel } from 'src/app/shared/models/queue.model';
import { QueueService } from 'src/app/shared/services/queue.service';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss']
})
export class QueueComponent implements OnInit {

  queuesStatus: StatusRabbitMQMessage;

  constructor(
    private nzModal: NzModalService,
    public queueService: QueueService,
    private router: Router
  ) { }

  ngOnInit() {
    this.queueService.loadQueues();

    this.queueService.queues$.pipe(untilDestroyed(this)).subscribe(
      (result) => {
        if (result.some(x => x.status == StatusRabbitMQMessage.Error)) return this.queuesStatus = StatusRabbitMQMessage.Error;
        if (result.some(x => x.status == StatusRabbitMQMessage.Started)) return this.queuesStatus = StatusRabbitMQMessage.Started;
        if (result.some(x => x.status == StatusRabbitMQMessage.Created)) return this.queuesStatus = StatusRabbitMQMessage.Created;
        if (result.some(x => x.status == StatusRabbitMQMessage.GenerateFiles)) return this.queuesStatus = StatusRabbitMQMessage.GenerateFiles;
        return this.queuesStatus = StatusRabbitMQMessage.Finished;
      }
    )
  }

  showModalError(error: string, queue: QueueModel): void {
    this.nzModal.error({
      nzTitle: 'Erro ao calcular estudo!',
      nzContent: `Mensagem de erro: ${error}`,
      nzOnOk: () => this.queueService.markAsRead(queue),
      nzClosable: false
    });
  }

  openStudy(queue: QueueModel) {
    this.queueService.markAsRead(queue);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['estudos', queue.correlationId])
    );
  }
}
