import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";
import { NzNotificationService } from "ng-zorro-antd/notification";

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {

    constructor(
        protected override router: Router,
        private keycloak: KeycloakService,
        private nzNotificationService: NzNotificationService
    ) {
        super(router, keycloak);
    }

    async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (!this.authenticated)
            await this.keycloak.login({ redirectUri: window.location.origin + state.url });

        let granted = false;

        const requiredRoles = route.data['roles'];
        if (!requiredRoles || requiredRoles.length === 0) {
            granted = true;
            return granted;
        }

        granted = this.roles.some(role => requiredRoles.includes(role));
        if (!granted) this.router.navigate(['permission-denied']);

        return granted;
    }
}