<nz-header>
    <div>
        <button nz-button nzType="text" nzShape="circle" (click)="triggerMenuClick()">
            <i nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
        </button>

        <div routerLink="/home">
             <img src="/assets/image/logo.png">
        </div>
    </div>

    <div>
        <ng-container *ngIf="isLoggedIn; else notLoggedIn">
            <button nz-button nzType="text" nzSize="large" nz-dropdown nzTrigger="click" [nzDropdownMenu]="editProfile"
                nz-tooltip nzTooltipTitle="Editar perfil" nzTooltipPlacement="bottom">
                <nz-avatar nzIcon="user" [nzSize]="24"></nz-avatar>
                <span>{{userProfile?.firstName}}</span>
                <i nz-icon nzType="down"></i>
            </button>
    
            <nz-dropdown-menu #editProfile="nzDropdownMenu">
                <ul nz-menu>
                    <li nz-menu-item data-cy="logout" (click)="logout()" routerLink="/auth">
                        <i nz-icon nzType="logout"></i>
                        Sair
                    </li>
                </ul>
            </nz-dropdown-menu>
        </ng-container>

        <ng-template #notLoggedIn>
            <button nz-button nzType="text" nzSize="large" (click)="login()">
                <i nz-icon nzType="login"></i>
                Login
            </button>
        </ng-template>
    </div>
</nz-header>